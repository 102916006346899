"use strict";

$(document).ajaxError(function (e, xhr, settings) {
    if (xhr.status == 401 || xhr.status == 403) {
        e.preventDefault();
        window.location =
            /* xhr.getResponseHeader("Location") || */ window.location;
    }
});

//Since IE6 doesn't properly z-order select boxes,
// remove menu animation and hide/show the student
// portfolio selector when we show/hide the menu.
$(document).ready(function () {

    $(".print").click(function () {
        window.print();
    });

    var counter = 0;
    setInterval(function () {
        var frames = 12;
        var frameWidth = 32;
        var offset = counter * -frameWidth;
        $(".dataTables_processing:visible span").css(
            "background-position",
            offset + "px 0px"
        );
        counter++;
        if (counter >= frames) counter = 0;
    }, 100);

    $(".toolbar ul li").mouseover(function () {
        $(this).addClass("hover");
    });
    $(".toolbar ul li").mouseout(function () {
        $(this).removeClass("hover");
    });

    $("form.no-submit").submit(function (e) {
        e.preventDefault();
    });

    $(document).on("keydown", "textarea,input[type=text]", function (event) {
        if (event.keyCode == 27) {
            event.returnValue = false;
            return false;
        }
    });

    $(document).on("focus", ".datePicker", function () {
        var dp = $(this);
        dp.datepicker({
            changeMonth: dp.attr("dpChangeMonth"),
            changeYear: dp.attr("dpChangeYear"),
            minDate: dp.attr("dpMinDate"),
            maxDate: dp.attr("dpMaxDate"),
            yearRange: dp.attr("dpYearRange")
        });
    });

    $(document).on("focus", ".dateTimePicker", function () {
        var dp = $(this);
        dp.datetimepicker({
            changeMonth: dp.attr("dpChangeMonth"),
            changeYear: dp.attr("dpChangeYear"),
            minDate: dp.attr("dpMinDate"),
            maxDate: dp.attr("dpMaxDate"),
            yearRange: dp.attr("dpYearRange")
        });
    });

    $(document).on("click", "[data-role='dialog-close']", function () {
        $($(this).data("target")).dialog("close");
    });

    // Expander for expandable text
    $("body").on("click", ".description button", function () {
        $(this)
            .find("span")
            .toggleClass("glyphicon-plus")
            .toggleClass("glyphicon-minus");
        $(this)
            .closest('.description')
            .find('.left')
            .toggleClass("expanded");
    });

    // Handle ajax handler errors; include techNote and message for debugging.
    $.fn.dataTable.ext.errMode = function (settings, techNote, message) {
        var response = settings.jqXHR;
        var html = response.responseText;
        document.open();
        document.write(html);
        document.close();

        // 500 - returns developer exception page
        // 200 - returns error page
        if (response.status === 200) {
            var title = $(document).attr("title");
            history.pushState({}, title, "/Error/Error");
        }
    };

    $(".dataTable").each(function (index, element) {
        $.fn.dataTableExt.oStdClasses.sPageButton = "btn btn-default ml-s mr-s";

        var orderColumn =
            $(element).data("order-column") != null
                ? $(element).data("order-column")
                : 0;
        var orderDirection =
            $(element).data("order-dir") != null
                ? $(element).data("order-dir")
                : "asc";

        $(element).dataTable({
            bFilter: false,
            bLengthChange: false,
            order: [[orderColumn, orderDirection]],
            bPaginate: $(element).data("paging") == true,
            bInfo: false,
            bSort: $(element).data("sort") != false,
            columnDefs: [
                {
                    targets: "datatable-nosort",
                    orderable: false
                }
            ]
        });
    });

    // Number extensions
    Number.prototype.toLocaleFixedString = function (digits) {
        return this.toLocaleString(undefined, { minimumFractionDigits: digits, maximumFractionDigits: digits });
    }

    Number.prototype.roundToDigits = function (digits) {
        return Math.round(this * Math.pow(10, digits)) / Math.pow(10, digits).toFixed(digits);
    }

    // String extensions
    String.prototype.capitalize = function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }

    $(document).ready(function () {
        $(".pager").change(function () {
            var url = pagerUrl;
            window.location =
                url +
                pagerSymbol +
                "page=" +
                this.options[this.selectedIndex].value;
        });
    });

    styleErrors();
});

// Restyle errors using bootstrap
function styleErrors() {
    $(".input-validation-error")
        .next(".field-validation-error")
        .addClass("glyphicon glyphicon-remove form-control-feedback")
        .attr("title", function () {
            return $(this).html();
        })
        .tooltip()
        .empty()
        .closest(".form-group")
        .addClass("has-error has-feedback");
}

function MaxLength(obj) {
    var maxlength = 1000;
    if ($(obj).val().length > maxlength) {
        $(obj).val(
            $(obj)
                .val()
                .substr(0, maxlength)
        );
    }
}

function textEllipsis(text, maxLength) {
    if (!maxLength) {
        maxLength = 28;
    }

    if (text.length > maxLength) {
        var trimmedString = text.substring(0, maxLength);
        return trimmedString + '<span title="' + text + '">&hellip;</span>';
    }
    return text;
}

function renderAvatar(avatarUrl, hasImage, userId, initials) {
    if (hasImage) {
        return `<div class="account-avatar"><img src="${avatarUrl}?userId=${userId}" /></div>`;
    } else {
        return `<div class="account-avatar-initials"><div>${initials}</div></div>`;
    }
}