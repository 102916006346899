$(function () {
    const $contactSupport = $("#contact-support");
    let contactCenterHtml = "";
    let fileName = ""
    let isPopoverShown = false;

    $contactSupport.on("click", () => {
        if (!contactCenterHtml || contactCenterHtml.length === 0) {
            $contactSupport.popover({
                content: `
                    <div class="contact-support-loader">
                      <img src="${ajaxLoaderDarkerUrl}" alt="Busy" />
                    </div>
                `,
                html: true,
                trigger: "manual",
                placement: "auto",
                title: "Contact support",
                container: ".contact-support-container",
                viewport: "nav"
            });

            $contactSupport.popover("show");
        } else {
            if (isPopoverShown) {
                $contactSupport.popover("hide");
            } else {
                setPopoverContent(contactCenterHtml);
            }
        }

    });

    $(document).on("change", ".contact-support-form", e => {
        const $attachement = $("#AttachmentFile");
        const file = $attachement[0].files[0];

        if (file) {
            const $attachmentErrorMessage = $("#attachment-file-size-error-msg");

            if (!$attachmentErrorMessage.hasClass("d-n")) {
                $attachmentErrorMessage.addClass("d-n");
            }

            if (file.size > 10 * 1024 * 1024) {
                $attachement.val("");
                fileName = "";
                $("label[for='AttachmentFile'").text("Choose a file");
                $attachmentErrorMessage.removeClass("d-n");

                return;
            }

            fileName = file.name;

            $("label[for='AttachmentFile'").text(fileName);
        }
    });

    $(document).on("submit", ".contact-support-form", e => {
        e.preventDefault();

        $("#loader-container").show();
        $("#loader").fadeIn(200);

        const formData = new FormData($(".contact-support-form")[0]);
        const $attachement = $("#AttachmentFile");
        const file = $attachement[0].files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = evt => {

                if (evt.target.readyState == FileReader.DONE) {
                    const cont = evt.target.result
                    const base64String = getB64Str(cont);

                    formData.set("Attachment", base64String);

                    sendDataAjax(formData, base64String);

                }
            };

            reader.readAsArrayBuffer($("#AttachmentFile")[0].files[0]);

        } else {
            formData.delete("AttachmentFile");
            sendDataAjax(formData, null);
        }
    });

    $(document).on("click", "#contact-support-cancel", e => {
        e.preventDefault();

        $contactSupport.popover("hide");
    });

    $(document).on("hidden.bs.popover", "#contact-support", () => isPopoverShown = false);

    $(document).on("shown.bs.popover", "#contact-support", () => {
        if (!isPopoverShown) {
            isPopoverShown = true;

            if (!contactCenterHtml) {

                $.ajax({
                    url: contactSupportAjaxUrl,
                    type: "POST",
                    async: true,
                    success: response => {
                        contactCenterHtml = response.PartialView;

                        if (isPopoverShown) {
                            setPopoverContent(contactCenterHtml);
                        }
                    }
                });
            }
        }
    });

    // bootstrap bug workaround
    $(document).on("click", function (e) {
        $("[data-toggle='popover'],[data-original-title]").each(function () {
            //the 'is' for buttons that trigger popups
            //the 'has' for icons within a button that triggers a popup
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $(".popover").has(e.target).length === 0) {
                (($(this).popover("hide").data("bs.popover") || {}).inState || {}).click = false  // fix for BS 3.3.6
            }

        });
    });

    // Close contact-support popup when clicking outside
    $("body").on("click", function (e) {
        if ($(e.target).attr("id") === "contact-support-icon" ||
            $(e.target).attr("id") === "contact-support") // IE
            return;

        if ($(e.target).parents(".contact-support-container").length === 0) {
            $contactSupport.popover("hide");
        }
    });

    // conversion of buffer to base64 string
    function getB64Str(buffer) {
        let binary = "";
        const bytes = new Uint8Array(buffer);

        for (let i = 0; i < bytes.byteLength; i++) {
            binary += String.fromCharCode(bytes[i]);
        }

        return window.btoa(binary);
    }

    function sendDataAjax(formData, content) {
        const model = {};

        formData.forEach((value, key) => {
            model[key] = value;
        });

        if (content) {
            model.Attachment = content;
            model.AttachmentFileName = fileName;
        }

        $.ajax({
            url: sendContactSupportEmailUrl,
            type: "POST",
            cache: false,
            processData: false,
            async: true,
            contentType: "application/json",
            data: JSON.stringify(model),
            success: response => {
                setPopoverContent(response.PartialView);
            },
            error: error => {
                setPopoverContent("<div class='text-center'>" +
                    "<p>We are sorry. Contacting support failed. You should try it again.</p>" +
                    "</div>" +
                    "<div class='text-center mt-l'>" +
                    "<button id='contact-support-cancel' type='button' class='btn btn-default'>Close</button>" +
                    "</div>");
            }
        }).always(() => {
            $("#loader-container").hide();
            $("#loader").fadeOut(200);
        });
    }

    function stylePopoverErrors() {
        $(".input-validation-error")
            .next(".field-validation-error")
            .addClass("form-control-feedback")
            .attr("title", function () {
                return $(this).html();
            })
            .tooltip()
            .empty()
            .closest(".form-group")
            .addClass("has-error has-feedback");
    }

    function setPopoverContent(content) {
        const popoverData = $contactSupport.data("bs.popover");

        popoverData.options.content = content;

        $contactSupport.popover("show");

        stylePopoverErrors();
    }
});