function redirectFromNotification(url, notificationId) {
    $.ajax({
        url: setNotificationsAsViewedUrl,
        type: "POST",
        async: true,
        data: { notificationIds: [notificationId] },
        success: response => {
            if (response.status == "Success") {
                location.href = url;
            }
        }
    });
}

$(document).on("click", ".notification-block-row", function (e) {
    const id = $(this).attr("data-id");
    const href = $(this).attr("data-url");

    if (href) {
        redirectFromNotification(href, id);
    }
});

$(document).on("click", ".AcceptCourseLevelRequestButton", function (e) {
    e.preventDefault();
    const button = this;
    resolveCourseLevelRequest(button, true)

});

$(document).on("click", ".RejectCourseLevelRequestButton", function (e) {
    e.preventDefault();
    const button = this;
    resolveCourseLevelRequest(button, false)
});

function resolveCourseLevelRequest(button, accessAllowed) {
    $.ajax({
        type: "POST",
        url: resolveCourseLevelReportRequestUrl,
        contentType: "application/json",
        data: JSON.stringify({ requestId: button.value, accessAllowed: accessAllowed }),
        success: function () {
            $.ajax({
                url: getLastNotificationsUrl,
                type: "POST",
                async: true,
                success: response => {
                    if ($(button).parents(".popover").length) {
                        const popoverData = $("#notification-menu").data("bs.popover");

                        popoverData.options.content = response.PartialView;

                        $("#notification-menu").popover("show");
                        popoverData.setContent();
                    } else {
                        location.reload();
                    }
                }
            });
        }
    });
}

$(function () {
    const $notificationMenu = $("#notification-menu");
    const popoverLoaderHtml = `<div class="notifications-popover-loader">
            <img src="${ajaxLoaderDarkerUrl}" alt="Busy" />
        </div>`;
    let notificationHtml = "";
    let isPopoverShown = false;

    $(document).on("click", ".close-notification-menu", e => {
        e.preventDefault();
        $notificationMenu.popover("hide");
    });

    $(document).on("click", "#settings-notification-mark-all-as-viewed-menu", e => {
        e.preventDefault();

        $.ajax({
            url: setNotificationsAsViewedUrl,
            type: "POST",
            async: true,
            success: response => {
                if (response.status == "Success") {
                    location.reload();
                }
            }
        });
    });

    $(document).on("click", ".notification-set-as-viewed", function (e) {
        const notificationBlock = $(e.target).closest(".notification-block");

        $.ajax({
            url: setNotificationsAsViewedUrl,
            type: "POST",
            async: true,
            data: { notificationIds: [notificationBlock.attr("id")] },
            success: response => {
                if (response.status == "Success") {
                    notificationBlock.removeClass("notification-not-viewed");
                    let notificationCount = parseInt($(".notification-badge").text());
                    notificationCount--;
                    $(".notification-badge").text(notificationCount);
                    if (notificationCount == 0) {
                        $(".notification-badge").hide();
                    }
                    $(e.target).hide();
                }
            }
        });
    });

    $notificationMenu.on("click", () => {
        if (!notificationHtml || notificationHtml.length === 0) {
            $notificationMenu.popover({
                content: popoverLoaderHtml,
                html: true,
                trigger: "manual",
                placement: "auto",
                title: `Notifications 
                    <div class="close-notification-menu glyphicon glyphicon-remove"></div>
                    <div class="settings-notification-menu">
                        <div class=:dropdown-toggle" data-toggle="dropdown">
                            <span class="glyphicon glyphicon glyphicon-option-vertical"></span></span>
                        </div>
                        <ul class="dropdown-menu pull-right" style="top: 30px;" role="menu">
                            <li>
                                <a id="settings-notification-mark-all-as-viewed-menu"><span class="glyphicon glyphicon-check"></span> Mark all as read</a>
                            </li>
                        </ul>
                    </div>`,
                container: ".notification-container",
                viewport: "nav",
                selector: ".test",
            });

            $notificationMenu.popover("show");
        } else {
            if (isPopoverShown) {
                $notificationMenu.popover("hide");
            } else {
                notificationHtml = "";
                setPopoverContent(popoverLoaderHtml);
            }
        }
    });

    $(document).on("hidden.bs.popover", "#notification-menu", () => isPopoverShown = false);

    $(document).on("shown.bs.popover", "#notification-menu", () => {
        if (!isPopoverShown) {
            isPopoverShown = true;

            if (!notificationHtml) {

                $.ajax({
                    url: getLastNotificationsUrl,
                    type: "POST",
                    async: true,
                    success: response => {
                        notificationHtml = response.PartialView;

                        if (isPopoverShown) {
                            setPopoverContent(notificationHtml);
                        }
                    }
                });
            }
        }
    });

    // Close notification popup when clicking outside
    $("body").on("click", function (e) {
        if ($(e.target).attr("id") === "notification-menu" ||
            ($(e.target).attr("id") === "notification-menu-icon")) {
            return;
        }
        if ($(e.target).parents(".notification-container").length === 0) {
            $notificationMenu.popover("hide");
        }
    });

    // bootstrap bug workaround
    $(document).on("click", function (e) {
        $("[data-toggle='popover'],[data-original-title]").each(function () {
            //the 'is' for buttons that trigger popups
            //the 'has' for icons within a button that triggers a popup
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $(".popover").has(e.target).length === 0) {
                (($(this).popover("hide").data("bs.popover") || {}).inState || {}).click = false  // fix for BS 3.3.6
            }

        });
    });

    function setPopoverContent(content) {
        let popoverData = $notificationMenu.data("bs.popover");
        if (popoverData) {
            popoverData.options.content = content;
            popoverData.setContent();
            $notificationMenu.popover("show");
        }
    }
})