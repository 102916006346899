/**
 * Automatically detect US datetime (m)m/(d)d/(yy)yy (h)h/m(m) (am|pm) date types.
 *
 *  @name Date MM/dd/yyyy mm:hh tt
 *  @summary Detect data which is in the date format `MM/dd/yyyy mm:hh tt`
 *  @author Andy McMaster
 */

jQuery.fn.dataTableExt.aTypes.unshift(
    function (sData) {
        // Convert from html tag
        // https://developer.mozilla.org/en-US/docs/Web/API/Node.nodeType
        if (sData.nodeType == 1) {
            sData = $(sData).text();
        }

        if (sData !== null && sData.match(/(\d{1,2})\/(\d{1,2})\/(\d{2,4}) (\d{1,2}):(\d{1,2}) (am|pm|AM|PM|Am|Pm)/)) {
            return 'datetime-us';
        }
        return null;
    }
);