$(function() {
    // Sets user agent so we can hide accessibility for certain browsers (IE 8, 9, 10)
    var b = document.documentElement;
    b.setAttribute("data-useragent", navigator.userAgent);
    b.setAttribute("data-platform", navigator.platform);

    var lineReader = {
        top: $("<div />", { id: "lineReaderTop" }).appendTo("body"),
        bottom: $("<div />", { id: "lineReaderBottom" }).appendTo("body"),
        enabled: false,
        animationSpeed: 350,
        height: 50,

        toggle: function(y, value) {
            if (value == "on" || (value == null && this.enabled == false)) {
                var that = this;
                this.bottom.animate(
                    { top: y + this.height / 2 },
                    this.animationSpeed
                );
                this.top.animate(
                    { bottom: $(window).height() - y + this.height / 2 },
                    this.animationSpeed,
                    function() {
                        $("html").on("mousemove", function(e) {
                            that.mouseMove(e);
                        });
                    }
                );
                this.enabled = true;
            } else if (
                value == "off" ||
                (value == null && this.enabled == true)
            ) {
                $("html").off("mousemove");
                this.top.animate({ bottom: "100%" }, this.animationSpeed);
                this.bottom.animate({ top: "100%" }, this.animationSpeed);
                this.enabled = false;
            }
        },

        mouseMove: function(e) {
            this.top.css(
                "bottom",
                $(window).height() - e.clientY + this.height / 2 + "px"
            );
            this.bottom.css("top", e.clientY + this.height / 2 + "px");
        }
    };

    function accessibilityContent() {
        return $("#toolbar").html();
    }

    $("#accessibility").popover({
        content: accessibilityContent,
        html: true,
        placement: "auto",
        title: "Accessibility Options",
        container: ".accessibility-container",
        viewport: "nav"
    });

    // set: a funtion that takes a value and sets the dom accordingly
    // rest: takes no parameters and resets to the dom back to it's original state
    function AccessibilityOption(set, reset) {
        var that = this;
        this.setDom = set;
        this.resetDom = reset;

        this.setValue = function(value, e) {
            if (this.value == value) this.reset();
            else {
                if (this.setDom) this.setDom(value, e);
                this.value = value;
            }
        };

        this.reset = function() {
            this.value = null;
            this.resetDom();
        };

        this.value;
    }

    var accessibility = {
        options: {},
        setOption: function(option, value, e) {
            if (value == "reset") this.options[option].reset();
            else this.options[option].setValue(value, e);

            this.saveSettings();
        },

        saveSettings: function() {
            $.ajax({
                type: "POST",
                url: accessibilityUrl,
                data: { accessibility: JSON.stringify(this.options) },
                success: function() {}
            });
        },

        init: function(initData) {
            var that = this;

            $("body").on(
                "click",
                "[data-accessibility][data-accessibility != reset]",
                function(e) {
                    var option = $(this).data("accessibility");
                    var value = $(this).data("accessibility-value");

                    that.setOption(option, value, e);
                }
            );

            var fontSizeOption = new AccessibilityOption(
                function(value) {
                    $("#font-sizes .btn.selected").removeClass("selected");
                    $(
                        "#font-sizes .btn[data-accessibility-value='" +
                            value +
                            "']"
                    ).addClass("selected");

                    $("body")
                        .addClass("font-size")
                        .css({
                            fontSize: value
                        });
                },
                function() {
                    $("#font-sizes .btn.selected").removeClass("selected");
                    $(
                        "#font-sizes .btn[data-accessibility-value='reset']"
                    ).addClass("selected");

                    $("body")
                        .removeClass("font-size")
                        .css({
                            fontSize: ""
                        });
                }
            );

            var contrastOption = new AccessibilityOption(
                function(value) {
                    var textColor = value.split(" ")[0];
                    var backgroundColor = value.split(" ")[1];

                    $("#contrast .btn.selected").removeClass("selected");
                    $(
                        "#contrast .btn[data-accessibility-value='" +
                            value +
                            "']"
                    ).addClass("selected");

                    $("body")
                        .addClass("contrast")
                        .css({
                            color: textColor,
                            background: backgroundColor,
                        });
                    document.body.style.setProperty("--ccfg", textColor);
                    document.body.style.setProperty("--ccbg", backgroundColor);
                },
                function() {
                    $("#contrast .btn.selected").removeClass("selected");
                    $(
                        "#contrast .btn[data-accessibility-value='reset']"
                    ).addClass("selected");

                    $("body")
                        .removeClass("contrast")
                        .css({
                            color: "",
                            background: ""
                        });
                    document.body.style.removeProperty("--ccfg");
                    document.body.style.removeProperty("--ccbg");
                }
            );

            var overlayOption = new AccessibilityOption(
                function(value, animate) {
                    var overlay = $("#overlay");
                    if (overlay.length == 0) {
                        overlay = $("<div id='overlay'></div>");
                        $("body").append(overlay);
                    }

                    $("#overlays .btn.selected").removeClass("selected");
                    $(
                        "#overlays .btn[data-accessibility-value='" +
                            value +
                            "']"
                    ).addClass("selected");

                    overlay.css("background-color", value);
                    overlay.animate({ bottom: "0" }, animate ? 350 : 0);
                },
                function() {
                    $("#overlays .btn.selected").removeClass("selected");
                    $(
                        "#overlays .btn[data-accessibility-value='reset']"
                    ).addClass("selected");

                    var overlay = $("#overlay");
                    if (overlay.length == 0) {
                        overlay = $("<div id='overlay'></div>");
                        $("body").append(overlay);
                    }

                    overlay.animate({ bottom: "100%" }, 500);
                }
            );

            var lineReaderOption = new AccessibilityOption(
                function(value, e) {
                    lineReader.toggle(e.pageY);
                    $("#extras #line-reader-button").addClass("selected");
                },
                function() {
                    lineReader.toggle(0, "off");
                    $("#extras #line-reader-button").removeClass("selected");
                }
            );

            // Hook up the reset button
            $("body").on(
                "click",
                "[data-accessibility=reset]",
                $.proxy(function() {
                    for (var index in this.options) {
                        this.options[index].reset();
                    }

                    this.saveSettings();
                }, this)
            );

            this.options["font-size"] = fontSizeOption;
            this.options["contrast"] = contrastOption;
            this.options["overlay"] = overlayOption;
            this.options["line-reader"] = lineReaderOption;

            // Set accessibility options for all presets
            if (initData) {
                for (var option in initData) {
                    if (option == "line-reader") continue;

                    this.options[option].setValue(
                        initData[option].value,
                        false
                    );
                }
            }

            // Turn on transitions for all accessibility changes that happen from now on.
            $("body").css(
                "transition",
                "font-size .5s, color .5s, background .5s"
            );
        }
    };

    accessibility.init(accessibilityValue);

    // Close accessibility popup when clicking outside
    // This is the same as setting the popover trigger to "focus" but iPad's don't support focus.
    $("body").on("click", function(e) {
        if (
            $(e.target).attr("id") !== "accessibility" &&
            $(e.target).parents("#accessibility").length === 0
        ) {
            $("#accessibility").popover("hide");
        }
    });

    // There is a known bug in bootstrap popover where the state is not updating properly for double clicking
    $(document).on("hidden.bs.popover", function(e) {
        $(e.target).data("bs.popover").inState.click = false;
    });

    // line reader is hidden by default and we show it if the user touches the mouse, then remove the event listener
    document.documentElement.addEventListener(
        "touchstart",
        function enableLineReader() {
            $("#line-reader-button-group").hide();
            document.documentElement.removeEventListener(
                "touchstart",
                enableLineReader,
                false
            );
        },
        false
    );
});
