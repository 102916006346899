// Augments any [data-dismissable-banner] on the page with a close button that remembers state in localstorage

(function () {
    function hash(str) {
        var ret = 23;
        for (var i = 0; i < str.length; i++) {
            ret = (ret * 17 + str.charCodeAt(i)) | 0;
        }
        return "" + ret;
    }
    $("[data-dismissable-banner]").each(function () {
        var $banner = $(this);
        var data = $banner.data("dismissable-banner");

        var storageKey = "dismissable-banner-" + data;
        var storageValue = hash($banner.text());

        if (localStorage.getItem(storageKey) === storageValue) {
            $banner.remove();
        } else {
            var $button = $(
                '<button type="button" class="close" aria-label="Close"><span aria-hidden="true">\u2715</span></button>'
            );
            $button.click(function () {
                localStorage.setItem(storageKey, storageValue);
                $banner.remove();
            });
            $banner.prepend($button);
        }
    });
    $("[data-session-dismissable-banner]").each(function () {
        var $banner = $(this);
        var data = $banner.data("session-dismissable-banner");

        var storageKey = "session-dismissable-banner-" + data;
        var storageValue = hash($banner.text().trim());

        var clearDismissedAlerts = $("#ClearDismissedAlerts").val();
        if (clearDismissedAlerts == "True") {         
            localStorage.removeItem(storageKey);
        }

        if (localStorage.getItem(storageKey) == storageValue) {
            $banner.remove();
        } else {
            var $button = $(
                '<button type="button" class="close" aria-label="Close"><span aria-hidden="true">\u2715</span></button>'
            );
            $button.click(function () {
                localStorage.setItem(storageKey, storageValue);
                $banner.remove();
            });
            $banner.prepend($button);
        }
    });
})();
